import Box from '@mui/material/Box';
import Dashboard from './Dashboard/Dashboard';
import Header from './Header/Header';

function MainView() {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
			<Box sx={{ marginBottom: '3%' }}>
				<Header />
			</Box>
			<Dashboard />
		</Box >
	);
}

export default MainView;
