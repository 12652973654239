interface IProps {
  size: number;
  counterClockwise: boolean;
}

const Cake = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <ellipse fill="#8899A6" cx={18} cy={26} rx={18} ry={10} />
    <ellipse fill="#CCD6DD" cx={18} cy={24.25} rx={18} ry={10} />
    <path
      fill="#DD2E44"
      d="M32.675 23.685c0 4.26-6.57 7.712-14.675 7.712S3.325 27.945 3.325 23.685c0-4.258 6.57-7.711 14.675-7.711 8.104 0 14.675 3.453 14.675 7.711z"
    />
    <path
      fill="#F4ABBA"
      d="M32.233 22.543c0 9.854-28.466 9.854-28.466 0v-8.759h28.466v8.759z"
    />
    <path
      fill="#DD2E44"
      d="M17.984 18.166c-8.984 0-14.218-4.132-14.218-4.132s-.016.924-.016 1.685c0 0 .032 4.898 2.572 4.898 2.459 0 2.28 2.348 3.834 2.591 1.541.241 1.712-.938 3.625-.938s2.25 2.106 4.203 2.106c2.289 0 2.477-2.106 4.389-2.106s2.132 1.224 3.386.885c1.507-.408.814-2.537 3.887-2.537 2.54 0 2.603-4.648 2.603-4.648 0-.76-.017-1.935-.017-1.935s-5.263 4.131-14.248 4.131z"
    />
    <path
      fill="#EA596E"
      d="M32.675 12.737c0 4.259-6.57 7.712-14.675 7.712S3.325 16.996 3.325 12.737 9.895 5.025 18 5.025c8.104 0 14.675 3.453 14.675 7.712z"
    />
    <path
      fill="#FFF8E8"
      d="M25.664 13.784c-.605 0-1.095-.49-1.095-1.095V5.025a1.095 1.095 0 1 1 2.19 0v7.664c0 .605-.49 1.095-1.095 1.095z"
    />
    <path
      fill="#FAAA35"
      d="M25.664 6.667c-1.162 0-2.076-.532-2.445-1.423-.32-.773-.479-2.45 2.058-4.986a.547.547 0 0 1 .774 0c2.537 2.537 2.378 4.213 2.058 4.986-.369.891-1.283 1.423-2.445 1.423z"
    />
    <path
      fill="#FFF8E8"
      d="M18 17.068c-.605 0-1.095-.49-1.095-1.095V8.31a1.095 1.095 0 1 1 2.19 0v7.664c0 .604-.49 1.094-1.095 1.094z"
    />
    <path
      fill="#FAAA35"
      d="M18 9.952c-1.162 0-2.076-.532-2.445-1.423-.321-.773-.479-2.45 2.058-4.986a.547.547 0 0 1 .774 0c2.537 2.537 2.378 4.213 2.058 4.986-.369.891-1.283 1.423-2.445 1.423z"
    />
    <path
      fill="#FFF8E8"
      d="M10.336 13.784c-.605 0-1.095-.49-1.095-1.095V5.025a1.095 1.095 0 1 1 2.19 0v7.664c0 .605-.49 1.095-1.095 1.095z"
    />
    <path
      fill="#FAAA35"
      d="M10.336 6.667c-1.162 0-2.076-.532-2.445-1.423-.321-.773-.479-2.45 2.058-4.986a.547.547 0 0 1 .774 0c2.537 2.537 2.378 4.213 2.058 4.986-.369.891-1.283 1.423-2.445 1.423z"
    />
  </svg>
);

function CakeEmoji(props: IProps) {
  const customStyle = {
	height: "auto",
	width: props.size + "em",
  };

  var className = "rotate linear infinite";
  if (props.counterClockwise) {
    className = "rotate-counterclockwise linear infinite";
  }
  return (
    <span className={className} style={customStyle} role="img">
      <Cake />
    </span>
  );
}

export default CakeEmoji;
