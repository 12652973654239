import CakeEmoji from "../../../emojis/CakeEmoji";
import Box from '@mui/material/Box';
import BringFormButton from "./BringFormButton/BringFormButton";


function Header() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'primary.light', position: 'relative' }}>
      <Box sx={{ display: 'flex', justify: 'center', alignItems: 'center' }}>
        <CakeEmoji size={10} counterClockwise={false}></CakeEmoji>
        <Box sx={{ margin: '10%' }}>
          <h1>CakeBusters</h1>
        </Box>
        <CakeEmoji size={10} counterClockwise={true}></CakeEmoji>
      </Box>
      <Box sx={{ position: 'absolute', top: 0, right: '3%', display: 'flex', height: '100%' }}>
        <BringFormButton>
          <h3>I'll bring cake</h3>
        </BringFormButton>
      </Box >
    </Box >
  );
}

export default Header;
