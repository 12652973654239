import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../../../styledComponents/StyledTableCell";
import { CaughtPerson, getCaughtPeople } from "../../../../communication/cake";

import dayjs from "dayjs";
import CaughtPeopleCakeButton from "./CaughtPeopleCakeButton/CaughtPeopleCakeButton";
import { useState, useEffect } from "react";

function CaughtPeople() {
  const [rows, setRows] = useState<CaughtPerson[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCaughtPeople();
      data.sort(function (a, b) {
        return a.date.getTime() - b.date.getTime();
      });
      setRows(data);
    };
    fetchData();
  }, []);

  return (
    <Box>
      <h2> Recently Caught People </h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Who?</StyledTableCell>
              <StyledTableCell align="right">When?</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {dayjs(row.date).format("dddd, DD.MM.YY")}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ p: 0 }}>
                  <CaughtPeopleCakeButton name={row.name} debtID={row.debtID}>
                    cake
                  </CaughtPeopleCakeButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CaughtPeople;
