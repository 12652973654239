import * as React from 'react';
import Button from '@mui/material/Button';
import CaughtPeopleCakeDialog from './CaughtPeopleCakeDialog/CaughtPeopleCakeDialog';

interface IProps {
	name: string;
	debtID: number;
}

function CaughtPeopleCakeButton(props: React.PropsWithChildren<IProps>) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	return (
		<div>
			<Button variant="contained" size="small" onClick={handleClickOpen}>
				{props.children}
			</Button>
			<CaughtPeopleCakeDialog open={open} setOpen={setOpen} name={props.name} debtID={props.debtID}/>
		</div>
	);
}

export default CaughtPeopleCakeButton;