import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#6ac9fc',
		},
		success: {
			main: '#53d806',
		},
	},
	typography: {
		h1: {
			color: 'red',
		},
		h2: {
			color: '#00ff00',
		},
	}

});

export default theme;