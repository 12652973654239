
interface IProps {
  size: number;
}

function ShortcakeEmoji(props: IProps) {
	const customStyle = {
		fontSize: props.size + 'px',
	};
	return (
		<span style={customStyle} role="img">🍰</span>
	);
}

export default ShortcakeEmoji;