import axios from 'axios';
import dayjs from 'dayjs';

const client = axios.create({
    baseURL: "/api/v1"
});

export const saveNewCaughtPerson = async (name: string) => {
	const userID = await getUserID(name);
	await client.post('/debts', { "user_id": userID, "date_caught": dayjs(new Date()).format("YYYY-MM-DD") })
};

export const saveCaughtBringCake = async (name: string, cake: string, date: string, debtID: number) => {
	await saveNewBringCake(name, cake, date);
	await deleteDebt(debtID);
};

export const deleteDebt = async (debtID: number) => {
	await client.delete(`/debts/${debtID}`)
}

export const saveNewBringCake = async (name: string, cake: string, date: string) => {
	const cakeID = await getCakeID(cake);
	const userID = await getUserID(name);

	await client.post('/deliveries', { "user_id": userID, "cake_id": cakeID, "date_delivered": date })
};

const getCakeID = async (name: string): Promise<number> => {
	// Creates the cake if it can't be found
	const { data } = await client.get('/cakes');
	const cakesInfo = data;

	const newCakeInfo = cakesInfo.filter((e: any) => e.name === name);
	var id;
	if (newCakeInfo.length === 1) {
		id = newCakeInfo[0].id
	} else if (newCakeInfo.length === 0) {
		id = await createCake(name);
	} else {
		alert(`Something went wrong! More than one cake with the name ${name} were found in the database.`)
	}
	return id
}

const getUserID = async (name: string): Promise<number> => {
	// Creates the user if it can't be found
	const { data } = await client.get('/users');
	const personsInfo = data;

	const caughtPersonInfo = personsInfo.filter((e: any) => e.name === name);
	var id;
	if (caughtPersonInfo.length === 1) {
		id = caughtPersonInfo[0].id
	} else if (caughtPersonInfo.length === 0) {
		id = await createPerson(name);
	} else {
		alert(`Something went wrong! More than one person with the name ${name} were found in the database.`)
	}
	return id
}

const createPerson = async (name: string): Promise<number> => {
	// Returns the id of the newly created user
	const { data } = await client.post('/users', { "name": name })
	return data.id;
}

const createCake = async (name: string): Promise<number> => {
	// Returns the id of the newly created cake
	const { data } = await client.post('/cakes', { "name": name })
	return data.id;
}

export interface CaughtPerson {
	name: string;
	date: Date;
	debtID: number
};

export const getCaughtPeople = async (): Promise<CaughtPerson[]> => {
	const { data } = await client.get('/debts');
	return await Promise.all(data.map(async (e: any) => {
		const date = new Date(e.date_caught);
		const name = await getUserNameFromID(e.user_id);
		const debtID = e.id;
		return { name: name, date: date, debtID:  debtID};
	}));
};

const getUserNameFromID = async (userID: number): Promise<string> => {
	const { data } = await client.get(`/users/${userID}`);
	return data.name;
};

export interface UpcomingCake {
	name: string;
	cakeName: string;
	date: Date;
};

export const getUpcomingCakes = async (): Promise<UpcomingCake[]> => {
	const { data } = await client.get('/deliveries');
	return await Promise.all(data.map(async (e: any) => {
		const name = await getUserNameFromID(e.user_id);
		const cakeName = await getCakeNameFromID(e.cake_id);
		const date = new Date(e.date_delivered);
		return { name: name, cakeName: cakeName, date: date };
	}));
};

const getCakeNameFromID = async (cakeID: number): Promise<string> => {
	const { data } = await client.get(`/cakes/${cakeID}`);
	return data.name;
};

export const getAllNames = async (): Promise<string[]> => {
	const { data } = await client.get('/users');
	return data.map((e: any) => { return e.name });
};