import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import StyledTableCell from '../../../../styledComponents/StyledTableCell';
import StyledTableRow from '../../../../styledComponents/StyledTableRow';
import ShortcakeEmoji from "../../../../emojis/ShortcakeEmoji";
import { getUpcomingCakes, UpcomingCake } from '../../../../communication/cake';
import { useState, useEffect } from 'react';

import dayjs from 'dayjs';

const removePastCakes = (data: UpcomingCake[]): UpcomingCake[] => {
  return data.filter((e) => {
    const lastMidnight = new Date();
    lastMidnight.setHours(0, 0, 0, 0);
    return e.date >= lastMidnight;
  });
}

function UpcomingCakes() {
  const [rows, setRows] = useState<UpcomingCake[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      let data = await getUpcomingCakes();
      data = removePastCakes(data);
      data.sort(function (a, b) { return a.date.getTime() - b.date.getTime() });
      setRows(data);
    };
    fetchData();
  }, []);

  return (
    <Box>
      <h2> Upcoming cakes </h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Who?</StyledTableCell>
              <StyledTableCell align="right">When?</StyledTableCell>
              <StyledTableCell align="right"><ShortcakeEmoji size={32} /> What?</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{dayjs(row.date).format("dddd, DD.MM.YY")}</StyledTableCell>
                <StyledTableCell align="right">{row.cakeName}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}


export default UpcomingCakes;