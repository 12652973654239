import * as React from 'react';
import { styled } from '@mui/material/styles';
import theme from '../../../../theme';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BringFormDialog from './BringFormDialog/BringFormDialog';

const StyledButton = styled(Button)`
  &:hover{
    border: solid 3px ${theme.palette.primary.dark};
    color: ${theme.palette.primary.dark};
  }
  border: solid 3px #fff;
  border-radius: 0px;
  color: #fff;
  margin-top: 25%;
  margin-bottom: 25%;
`

interface IProps { }

function BringFormButton(props: React.PropsWithChildren<IProps>) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(!open);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center'
			}}
		>
			<StyledButton variant="outlined" onClick={handleClickOpen}>
				{props.children}
			</StyledButton>
			<BringFormDialog open={open} setOpen={setOpen} />
		</Box>
	);
}

export default BringFormButton;