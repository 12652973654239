import { useState, useEffect, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import { CakeOutlined, CakeTwoTone } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { saveNewCaughtPerson, saveNewBringCake, getAllNames } from '../../../../../communication/cake';

interface IProps {
	open: boolean;
	setOpen(open: boolean): any;
}

interface CakeForm {
	name: string;
	cake: string;
	date: string;
}

function BringFormDialog(props: IProps) {
	const [cakeForm, setCakeForm] = useState<CakeForm>({
		name: "",
		cake: '',
		date: "",
	});
	const [isAllFilled, setIsAllFilled] = useState(false);
	const [allUsernames, setAllUsernames] = useState<string[]>([]);
	const [bringCakeSelf, setBringCakeSelf] = useState(false);

	useEffect(() => {
		const { name, cake, date } = cakeForm;
		if (bringCakeSelf) {
			setIsAllFilled(cake.length > 0 && date !== "" && name.length > 0);
		} else {
			setIsAllFilled(name.length > 0);
		}
	}, [cakeForm, bringCakeSelf]);

	useEffect(() => {
		const fetchData = async () => {
			let allUsernames = await getAllNames();
			setAllUsernames(allUsernames);
		};
		fetchData();
	}, []);

	const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
		let data: CakeForm = { ...cakeForm };
		data[event.target.name as keyof CakeForm] = event.target.value;
		setCakeForm(data);
	}

	const handleFormChangeAutocomplete = (event: React.SyntheticEvent, value: string, reason: string) => {
		// Workaround since I can't turn the SyntheticEvent into a ChangeEvent to call the function above...
		let data: CakeForm = { ...cakeForm };
		data.name = value;
		setCakeForm(data);
	}

	const handleClose = async (wasCanceled: boolean) => {
		props.setOpen(false);
		if (!wasCanceled) {
			if (bringCakeSelf) {
				await saveNewBringCake(cakeForm.name, cakeForm.cake, cakeForm.date);
			} else {
				await saveNewCaughtPerson(cakeForm.name);
			}
			window.location.reload();
		}
	};

	const HeartCheckBox = () => {
		return (
			<Checkbox
				icon={<CakeOutlined />}
				checkedIcon={<CakeTwoTone />}
				checked={bringCakeSelf}
				onChange={() => { setBringCakeSelf(!bringCakeSelf) }}
				color="success"
				sx={{ '& .MuiSvgIcon-root': { fontSize: 48 } }}
			/>
		);
	};

	const additionalInfo = () => {
		if (bringCakeSelf) {
			return (
				<>
					<TextField
						margin="dense"
						id="cake"
						label="Which cake?"
						name="cake"
						defaultValue={cakeForm.cake}
						type="text"
						fullWidth
						variant="standard"
						onChange={handleFormChange}
					/>
					<TextField
						margin="dense"
						id="date"
						label="When will you bring cake"
						name="date"
						type="date"
						defaultValue={cakeForm.date}
						InputLabelProps={{ shrink: true }}
						fullWidth
						variant="standard"
						onChange={handleFormChange}
					/>
				</>
			);
		}
	};

	return (
		<Dialog open={props.open} onClose={handleClose}>
			<DialogTitle>Time for a New Cake</DialogTitle>
			<DialogContent>
				<FormControlLabel control={HeartCheckBox()} color="warning" label="I'll bring cake myself" />
				<Autocomplete
					id="name"// 
					fullWidth
					autoHighlight
					freeSolo
					options={allUsernames}
					sx={{ width: 300 }}
					onInputChange={handleFormChangeAutocomplete}
					renderInput={(params: object) => {
						return <TextField
							{...params}
							autoFocus
							margin="dense"
							id="name"
							name="name"
							label="Name"
							type="text"
							fullWidth
							variant="standard"
							onChange={handleFormChange}
						/>
					}}
				/>
				{additionalInfo()}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose(true)}>Cancel</Button>
				<Button onClick={() => handleClose(false)} disabled={!isAllFilled} variant="contained" >Ok</Button>
			</DialogActions>
		</Dialog>
	);
}

export default BringFormDialog;