// import { Divider } from '@mui/material';
import Box from "@mui/material/Box";
import CaughtPeople from "./CaughtPeople/CaughtPeople";
import UpcomingCakes from "./UpcomingCakes/UpcomingCakes";
// import HallOfFame from './HallOfFame/HallOfFame';
// import CakeRanking from './CakeRanking/CakeRanking';
// import CupcakeEmoji from "../../../emojis/CupcakeEmoji"

function Dashboard() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Box sx={{ flex: "0.6" }}>
          <UpcomingCakes />
        </Box>
        <Box sx={{ flex: "0.3", alignContent: "center" }}>
          <CaughtPeople />
        </Box>
      </Box>
      {/* // Those features need connection to the API to work
			<Box sx={{ display: 'flex', margin: '4%', alignItems: 'center' }}>
				<Box sx={{flexGrow: '0.2'}}>
					<Divider />
				</Box>
				<CupcakeEmoji size={10} />
				<Box sx={{flexGrow: '0.2'}}>
					<Divider />
				</Box>
				<CupcakeEmoji size={10} />
				<Box sx={{flexGrow: '0.2'}}>
					<Divider />
				</Box>
				<CupcakeEmoji size={10} />
				<Box sx={{flexGrow: '0.2'}}>
					<Divider />
				</Box>
				<CupcakeEmoji size={10} />
				<Box sx={{flexGrow: '0.2'}}>
					<Divider />
				</Box>
				<CupcakeEmoji size={10} />
				<Box sx={{flexGrow: '0.2'}}>
					<Divider />
				</Box>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
				<Box sx={{ flexGrow: '0.45' }}>
					<HallOfFame />
				</Box>
				<Box sx={{ flexGrow: '0.45' }}>
					<CakeRanking />
				</Box>
			</Box> */}
    </Box>
  );
}

export default Dashboard;
