import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:first-of-type': {
    backgroundColor: theme.palette.success.light,
    fontWeight: "bold",
  },
}));

export default StyledTableRow;