import { useState, useEffect, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { saveCaughtBringCake } from '../../../../../../communication/cake';
import DeleteCakeDebtButton from './DeleteCakeDebtButton/DeleteCakeDebtButton';

interface IProps {
	open: boolean;
	setOpen(open: boolean): any;
	name: string;
	debtID: number;
}

interface CakeForm {
	name: string;
	cake: string;
	date: string;
}

function CaughtPeopleCakeDialog(props: IProps) {
	const [cakeForm, setCakeForm] = useState<CakeForm>({
		name: props.name,
		cake: '',
		date: ""
	});
	const [isAllFilled, setIsAllFilled] = useState(false);

	useEffect(() => {
		setIsAllFilled(cakeForm.cake.length > 0 && cakeForm.date !== "");
	}, [cakeForm]);

	const handleClose = async (wasCanceled: boolean) => {
		props.setOpen(false);
		if (!wasCanceled) {
			await saveCaughtBringCake(cakeForm.name, cakeForm.cake, cakeForm.date, props.debtID);
			window.location.reload();
		}
	};

	const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
		let data: CakeForm = { ...cakeForm };
		data[event.target.name as keyof CakeForm] = event.target.value;
		setCakeForm(data);
	}

	return (
		<Dialog open={props.open} onClose={handleClose}>
			<DialogTitle>Time for a New Cake</DialogTitle>
			<DialogContent>
				<TextField
					disabled
					margin="dense"
					id="name"
					label="Name"
					type="text"
					defaultValue={cakeForm.name}
					name="name"
					fullWidth
					variant="standard"
				/>
				<TextField
					autoFocus
					margin="dense"
					id="cake"
					label="Which cake?"
					type="text"
					defaultValue={cakeForm.cake}
					fullWidth
					name="cake"
					variant="standard"
					onChange={handleFormChange}
				/>
				<TextField
					margin="dense"
					id="date"
					label="When will you bring cake"
					type="date"
					InputLabelProps={{ shrink: true }}
					defaultValue={cakeForm.date}
					fullWidth
					name="date"
					variant="standard"
					onChange={handleFormChange}
				/>
			</DialogContent>
			<DialogActions style={{ justifyContent: "space-between" }}>
				<DeleteCakeDebtButton  debtID={props.debtID} handleClose={handleClose}/>
				<Box>
					<Button onClick={() => handleClose(true)}>Cancel</Button>
					<Button onClick={() => handleClose(false)} disabled={!isAllFilled} variant="contained" >Ok</Button>
				</Box>
			</DialogActions>
		</Dialog >
	);
}

export default CaughtPeopleCakeDialog;