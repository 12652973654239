import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteDebt } from '../../../../../../../communication/cake';

interface IProps {
	debtID: number;
	handleClose(open: boolean): any;
}

function DeleteCakeDebtButton(props: React.PropsWithChildren<IProps>) {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = async (wasCanceled: boolean) => {
		setOpen(false);
		if (!wasCanceled) {
			await deleteDebt(props.debtID);
			props.handleClose(true);
			window.location.reload();
		}
	};

	return (
		<div>
			<Button onClick={handleClickOpen} size="small" color="error" >I was wrongfully accused!</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Are you sure you want to delete this debt?</DialogTitle>
				<DialogContent>
					Consider bringing a cake anyway. Everyone will be happy!
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)}>Cancel</Button>
					<Button onClick={() => handleClose(false)} variant="contained" >Delete</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default DeleteCakeDebtButton;